<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">囤货管理</div>
		
		<div class="mt20">
			<a-layout>
				<a-layout-header class="layout-classes-header">
					<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
						<a-menu-item key="goods">囤货商品</a-menu-item>
						<a-menu-item key="order">囤货订单</a-menu-item>
					</a-menu>
				</a-layout-header>
				<a-layout-content class="layout-classes-content">
					<div v-if="selectCurrent == 'goods'">
						<store-goods></store-goods>
					</div>
					<div v-if="selectCurrent == 'order'">
						<store-order></store-order>
					</div>
				</a-layout-content>
			</a-layout>
		</div>
	</div>
				
</template>

<script>
	import storeGoods from './components/Store/goods.vue';
	import storeOrder from './components/Store/order.vue';
	export default {
		components:{
			storeGoods,
			storeOrder,
		},
		data() {
			return {
				selectCurrent: 'goods',
			}
		},
		methods: {
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
		}
	}
</script>

<style>
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: 800px;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
